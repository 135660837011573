.button-load-more-items-root {
  display: flex;
  justify-content: center;
  /* margin-top: 2.5rem 0 2.5rem; */
}

/* .button-load-more-items-root--small {
  margin: 1rem 0 0.5rem;
} */

/* @media screen and (max-width: 599px) {
  .button-load-more-items-root {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .button-load-more-items-root--small {
    margin: 1rem 0 0.5rem;
  }
} */
