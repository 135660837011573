@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.desktop-nav-menu {
  &__avatar {
    box-sizing: border-box;
    font-family: $font-primary;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: linear-gradient(-45deg, $color-data-2, $color-data-3);
    color: $color-white;
    letter-spacing: 1px;
    margin: 0;
    text-align: center;
    @include center-xy-axis-horizontal;

    & span {
      display: block;
      text-align: center;
      @include center-xy-axis-horizontal;
    }

    @include respond(bp500) {
      width: 28px;
      height: 28px;
      font-size: 0.95rem;
    }
  }

  &__account-info {
    box-sizing: border-box;
    font-family: $font-primary;
    padding: 0.5rem 0.25rem 1.15rem;
    margin: 0 0.75rem 0.5rem;
    max-width: 272px;
    color: $color-data-1;
    border-bottom: 1px solid $color-data-1;
    @include break-overflown-word;

    & p {
      display: block;
      @include break-overflown-word;
      margin: 0;
    }
  }

  &__account-name {
    text-transform: uppercase;
    font-weight: 700;
  }

  &__account-type {
    font-size: 0.8rem;
    font-weight: 700;
    color: $color-data-2;
    position: relative;
    bottom: 2px;
    margin: 0 0 0.1rem !important;
  }

  &__account-email {
    font-size: 0.85rem;
  }
}
