@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.sale-prop-note-item {
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  padding: 15px 20px;
  width: 100%;
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;
  cursor: pointer;
  color: $color-primary-dark;
  transition: all 0.2s ease-in-out;
  display: grid;
  grid-template-columns: minmax(100px, 1fr) max-content;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: space-between;

  & svg {
    transition: all 0.2s ease-in-out !important;
  }

  &:hover,
  &:active {
    // background-color: rgba($color-primary-dark, 0.9);
    // color: #fff;

    // & svg {
    //   color: #fff;
    // }
  }

  &__data {
    &--full-width {
      grid-column: 1 / -1;
    }
  }

  &__data-item {
    display: block;
    margin: 0;
    max-width: 100%;
    font-size: 0.85rem;
    font-weight: 700;
    @include break-overflown-word;

    &--thin {
      font-weight: 400;
      margin-bottom: 0.3rem;
    }

    &--wide {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 0.5rem;
      align-items: center;
    }

    &--highlighted {
      font-weight: 700;
      color: $color-data-2;

      & ~ p {
        color: $color-data-2;
      }
    }
  }

  &__txt-highlight {
    color: $color-data-2;
  }

  &__status {
    font-family: $font-primary;
    font-size: 0.7rem;
    padding: 0.1rem;
    color: #fff;
    font-weight: 700;
    text-align: center;
    border-radius: 10px;
    text-transform: uppercase;

    &--positive_light {
      background-color: $color-data-2;
    }

    &--positive_dark {
      background-color: $color-data-1;
    }

    &--negative {
      background-color: $color-secondary;
    }

    &--warning {
      background-color: $color-ambar;
      color: #000;
    }

    &--neutral {
      background-color: #888;
    }
  }

  &__user-data {
    display: grid;
    grid-template-columns: max-content minmax(100px, 1fr);
    align-items: center;
    grid-column-gap: 0.4rem;
    margin-top: 1rem;
    max-width: 100%;

    & svg {
      font-size: 1.55rem;
      color: $color-primary-dark;
      transition: all 0.2s ease-in-out !important;
    }
  }

  &__user-data-content {
    max-width: 100%;
    @include break-overflown-word;

    & p {
      display: block;
      font-size: 0.7rem;
      max-width: 100%;
      @include break-overflown-word;
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 7px;
    align-items: center;
  }

  &__details {
    grid-column: 1 / -1;
  }

  &__details-content {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #bbb;
  }

  &__whatsapp-cta {
    padding: 0.35rem 0.2rem;
    background-color: $color-data-2;
    color: #fff;
    border-radius: 25px;
    text-transform: uppercase;
    cursor: pointer;
    font-family: $font-primary;
    font-weight: 700;
    transition: all 0.2s;
    @include center-xy-axis-horizontal;

    &:hover,
    &:active {
      background-color: rgba($color-data-2, 0.9);
    }

    &--light {
      background-color: $color-data-3;
      color: $color-data-1;

      &:hover,
      &:active {
        background-color: rgba($color-data-3, 0.9);
      }
    }

    &--disabled {
      color: #fff;
      background-color: #bbb;

      &:hover,
      &:active {
        color: #fff;
        background-color: #bbb;
      }
    }
  }
}
