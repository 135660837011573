@import "../abstracts/animations";
@import "../abstracts/mixins";
@import "../abstracts/variables";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-title;
  font-weight: 700;
}

.heading-secondary {
  font-weight: 700;
  display: inline-block;
  color: $color-primary-dark;
  transition: all 0.2s;
  margin: 0;

  &--sm {
    font-size: 2.25rem;
  }

  &--hidden {
    display: none;
  }
}

.heading-tertiary {
  color: $color-grey-dark-2;
  font-weight: 400;
  margin: 0;

  &--capitalized {
    text-transform: capitalize;
  }

  &--sm {
    font-size: 1.8rem;
  }
}

.heading-fourth {
  color: $color-grey-light-2;
  font-size: 2rem;
  font-weight: 300;
}

.sidebar-legal {
  font-size: 1.2rem;
  color: $color-grey-light-3;
  text-align: center;
  padding: 2.5rem;
  margin-top: 2rem;
}

.terms-agreement {
  display: inline-block;
  margin-top: 1.5rem;
  color: $color-grey-light-3;
  text-align: center;
  font-size: 1.3rem;

  &__link {
    &,
    &:link,
    &:visited {
      text-decoration: underline;
      color: $color-grey-light-3;
      transition: all 0.2s;
    }

    &:hover,
    &:active {
      color: $color-grey-light-1;
    }
  }
}

.text-700 {
  font-weight: 700;
}

.subheading {
  display: inline-block;
  font-family: $font-primary;
  font-weight: 700;
  margin-top: 10px;
}
