.searchbar-filters-container {
  margin-top: 20px;
}

.filter-radio-container {
  display: flex;
  position: relative;
  height: auto;
  width: 100%;
}

.searchbar-filter-select {
  display: grid;
  height: auto;
  grid-template-columns: max-content max-content max-content max-content max-content max-content;
  grid-column-gap: 1rem;
  align-items: flex-start;
  align-content: flex-start;
}

.searchbar-time-period-container {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.searchbar-time-period-container-no-margin-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.searchbar-range-container {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.searchbar-loading-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
}

.searchbar-filter-info {
  font-weight: 700;
  text-align: center;
}

.searchbar-filter-info--last {
  padding-bottom: 1.5rem;
  border-bottom: solid 1px #bbb;
}

@media screen and (max-width: 1279px) {
  .searchbar-filter-select {
    grid-template-columns: max-content max-content max-content max-content;
  }
}

@media screen and (max-width: 959px) {
  .searchbar-filter-select {
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
  }
}

.filter-menu {
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-self: start;
  padding: 0 30px;
  grid-gap: 1rem;
  box-sizing: border-box;

  &__actions {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;

    & button {
      margin-right: 0.3rem;
      width: 30px;
      height: 30px;
    }
  }
}
