@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.signature {
  display: flex;
  flex-direction: column;
  position: relative;

  &__title {
    margin: 0.3rem 0 1rem;
    font-weight: 300;
    font-size: 1.2rem;
    text-align: center;
  }

  // &__persons {
  // }

  &__persons-heading {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 0.5rem;
    align-items: center;
    margin: 0.75rem 0 0.5rem;
  }

  &__persons-title {
    font-weight: 700;
    margin: 0;
  }

  &__persons-subtitle {
    @include break-overflown-word;
    font-size: 0.9rem;
    margin: 0;
    margin-bottom: 0.2rem;
  }

  &__person {
    border: 1px solid $color-primary-dark;
    border-radius: $default-border-radius;
    display: flex;
    position: relative;
    box-sizing: border-box;

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    &--disabled {
      border: 1px solid #009688;
    }
  }

  &__person-actions {
    display: flex;
    flex-direction: column;
    margin-right: 0.85rem;
    padding-right: 1px;
  }

  &__person-action {
    @include center-xy-axis-vertical;
    cursor: pointer;
    transition: all 0.2s;
    height: 100%;
    margin: auto 0;

    &--edit {
      color: #fff;
      background-color: $color-primary-dark;

      &:hover {
        background-color: rgba($color-primary-dark, 0.9);
      }

      &:active {
        background-color: rgba($color-primary-dark, 0.8);
      }
    }

    &--delete {
      color: #212121;
      background-color: #999;

      &:hover {
        background-color: rgba(#999, 0.9);
      }

      &:active {
        background-color: rgba(#999, 0.8);
      }
    }

    &--success {
      color: #fff;
      background-color: $color-primary-dark;

      &:hover {
        background-color: rgba($color-primary-dark, 0.9);
      }

      &:active {
        background-color: rgba($color-primary-dark, 0.8);
      }
    }

    &--disabled {
      color: #fff;
      background-color: #009688;
      cursor: default;

      &:hover {
        background-color: #009688;
      }

      &:active {
        background-color: #009688;
      }
    }
  }

  &__person-data {
    padding: 0.5rem 0.85rem 0.5rem 0;

    & p {
      margin: 0.25rem 0;
      font-size: 0.9rem;
      @include break-overflown-word;
    }
  }

  &__persons-delete-btn {
    & svg {
      font-size: 20px;
    }
  }

  &__validity {
    width: 100%;
    margin-top: 1rem;

    & input {
      height: 55px;
    }

    & .MuiOutlinedInput-adornedStart {
      padding: 0 0 0 14px;
    }

    & .MuiOutlinedInput-input {
      padding: 18.5px 14px;
      height: 20px;
    }

    & .MuiFilledInput-input {
      padding: 27px 12px 10px;
      height: 20px;
    }
  }

  &__action {
    width: 100%;
    margin-top: 1rem;

    & button {
      width: 100%;
    }
  }
}
