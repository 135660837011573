@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.help {
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #777;
    transition: all 0.2s;

    &--active {
      color: $color-primary-light;
    }
  }

  &__content {
    box-sizing: border-box;
    padding: 0.75rem;
    min-width: 250px;
    max-width: 350px;
  }

  &__content-p {
    @include break-overflown-word;

    font-size: 0.9rem;
    margin: 0;
  }
}
