.form-inputs-grid-1fr-1fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-1fr {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-1fr-no-gap {
  display: grid;
  grid-template-columns: 1fr;
}

.form-inputs-grid-100 {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
}

.form-inputs-grid-link-txt {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
}

.form-inputs-grid-60px-1fr {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-70px-1fr {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-max-1fr {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-1fr-max {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  grid-gap: 1rem;
}

.form-inputs-grid-max-max {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-gap: 1rem;
}

.form-inputs-grid-80px-1fr {
  display: grid;
  grid-template-columns: 125px 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-95px-1fr {
  display: grid;
  grid-template-columns: 95px 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-105px-1fr {
  display: grid;
  grid-template-columns: 105px 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-125px-1fr {
  display: grid;
  grid-template-columns: 125px 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-125px-1fr-1fr {
  display: grid;
  grid-template-columns: 125px 1fr 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-3fr-1fr {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-1fr-3fr {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1rem;
}

.form-inputs-grid-1fr-4fr {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1rem;
}

.form-inputs-grid-1fr-2fr-2fr {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-gap: 1rem;
}

.form-inputs-grid-3fr-responsive {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-4fr-responsive {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-200px-200px {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-gap: 0.5rem;
}

.form-inputs-grid-100-100-1fr {
  display: grid;
  grid-template-columns: 100px 100px 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-50-50-1fr {
  display: grid;
  grid-template-columns: 50px 50px 1fr;
  grid-gap: 1rem;
}

.form-inputs-grid-max-1fr-1fr {
  display: grid;
  grid-template-columns: max-content 1fr 1fr;
  grid-gap: 1rem;
}

@media screen and (max-width: 1279px) {
  .form-inputs-grid-responsive-lg-screen {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 959px) {
  .form-inputs-grid-responsive {
    grid-template-columns: 1fr;
  }

  .form-inputs-grid-3fr-responsive {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .form-inputs-grid-4fr-responsive {
    grid-template-columns: 1fr 1fr;
  }

  .form-inputs-grid-200px-200px {
    display: grid;
    grid-template-columns: 200px;
    grid-gap: 0.5rem;
  }
}

@media screen and (max-width: 599px) {
  .form-inputs-grid-responsive-sm-screen {
    grid-template-columns: 1fr;
  }

  .form-inputs-grid-100-100-1fr {
    display: grid;
    grid-template-columns: 60px 60px 1fr;
    grid-gap: 1rem;
  }

  .form-inputs-grid-max-1fr-1fr {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
  }
}
