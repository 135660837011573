@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.input-submit-status {
  &__cta,
  &__status {
    & svg {
      font-size: 1.7rem;
    }

    &:not(:disabled) {
      & svg {
        color: $color-primary;
      }
    }

    &--teal {
      & svg {
        color: $color-teal !important;
      }
    }

    &--red {
      & svg {
        color: $color-secondary !important;
      }
    }

    &--ambar {
      & svg {
        color: $color-ambar !important;
      }
    }
  }

  &__status {
    cursor: help;
  }

  &__input-txt {
    &--teal {
      color: $color-teal !important;
    }

    &--red {
      color: $color-secondary !important;
    }

    &--ambar {
      color: $color-ambar !important;
    }
  }
}
