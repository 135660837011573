// Folding cube spinner animation
@-webkit-keyframes animation-spinner-folding-cubes {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes animation-spinner-folding-cubes {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

// Grid scale spinner animation
@-webkit-keyframes animation-spinner-grid-scale {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes animation-spinner-grid-scale {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

// Standard circle spinner animation
@-webkit-keyframes animation-spinner-std-circle {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes animation-spinner-std-circle {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes animation-background-desktop {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.8;
    border-radius: 2%;
  }

  100% {
    transform: translateY(-100rem) rotate(720deg);
    opacity: 0;
    border-radius: 60%;
  }
}

@keyframes animation-background-mobile {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.6;
    border-radius: 2%;
  }

  100% {
    transform: translateY(-100rem) rotate(720deg);
    opacity: 0;
    border-radius: 60%;
  }
}
