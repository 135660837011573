@keyframes ldio-050ogejridoo {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(22.5deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
.ldio-050ogejridoo > div {
  transform-origin: 100px 100px;
  animation: ldio-050ogejridoo 0.35714285714285715s infinite linear;
}
.ldio-050ogejridoo > div div {
  position: absolute;
  width: 30px;
  height: 156px;
  background: #05445e;
  left: 100px;
  top: 100px;
  transform: translate(-50%, -50%);
}
.ldio-050ogejridoo > div div:nth-child(1) {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.ldio-050ogejridoo > div div:nth-child(6) {
  width: 64px;
  height: 64px;
  background: #eee;
  border-radius: 50%;
}
.ldio-050ogejridoo > div div:nth-child(3) {
  transform: translate(-50%, -50%) rotate(45deg);
}
.ldio-050ogejridoo > div div:nth-child(4) {
  transform: translate(-50%, -50%) rotate(90deg);
}
.ldio-050ogejridoo > div div:nth-child(5) {
  transform: translate(-50%, -50%) rotate(135deg);
}
.loadingio-spinner-gear-9psd9pl45gd {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-050ogejridoo {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-050ogejridoo div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

@keyframes ldio-8i84ida6juc {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-8i84ida6juc > div > div {
  transform-origin: 125px 125px;
  animation: ldio-8i84ida6juc 3.125s linear infinite;
  opacity: 0.8;
}
.ldio-8i84ida6juc > div > div > div {
  position: absolute;
  left: 37.5px;
  top: 37.5px;
  width: 87.5px;
  height: 87.5px;
  border-radius: 87.5px 0 0 0;
  transform-origin: 125px 125px;
}
.ldio-8i84ida6juc > div div:nth-child(1) {
  animation-duration: 0.78125s;
}
.ldio-8i84ida6juc > div div:nth-child(1) > div {
  background: #189ab4;
  transform: rotate(0deg);
}
.ldio-8i84ida6juc > div div:nth-child(2) {
  animation-duration: 1.0416666666666667s;
}
.ldio-8i84ida6juc > div div:nth-child(2) > div {
  background: #75e6da;
  transform: rotate(0deg);
}
.ldio-8i84ida6juc > div div:nth-child(3) {
  animation-duration: 1.5625s;
}
.ldio-8i84ida6juc > div div:nth-child(3) > div {
  background: #05445e;
  transform: rotate(0deg);
}
.ldio-8i84ida6juc > div div:nth-child(4) {
  animation-duration: 3.125s;
}
.ldio-8i84ida6juc > div div:nth-child(4) > div {
  background: #3c777e;
  transform: rotate(0deg);
}
.loadingio-spinner-wedges-lnysajxxlyq {
  width: 250px;
  height: 250px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-8i84ida6juc {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-8i84ida6juc div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

@media print {
  .loadingio-spinner-gear-9psd9pl45gd {
    display: none;
  }

  .loadingio-spinner-wedges-lnysajxxlyq {
    display: none;
  }
}
