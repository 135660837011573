@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.import-mapping {
  &__title {
    margin: 15px 0 15px !important;

    @include respond(bp1200) {
      padding: 0 15px;
    }
  }

  &__row {
    display: table-row !important;

    &--1 {
      width: 33%;

      @include respond(bp1200) {
        width: 33%;
      }
    }

    &--2 {
      width: 33%;

      @include respond(bp1200) {
        width: 0;
      }
    }

    &--3 {
      width: 34%;

      @include respond(bp1200) {
        width: 67%;
      }
    }

    @include respond(bp1200) {
      grid-template-columns: 1fr 2fr !important;
    }
  }

  &__header-cell {
    font-weight: 700 !important;

    & p,
    & span {
      @include break-overflown-word;

      @include respond(bp800) {
        font-size: 0.8rem !important;
      }
    }
  }

  &__cell {
    & p,
    & span {
      @include break-overflown-word;

      @include respond(bp800) {
        font-size: 0.8rem !important;
      }
    }
  }
}
