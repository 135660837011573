@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.widget-zoe {
  &,
  & * {
    z-index: 1001;
  }
  box-sizing: border-box;

  &--no-display {
    display: none !important;
  }

  &__chat-container {
    box-sizing: border-box;
    width: 420px;
    max-width: 100vw;
    height: 700px;
    max-height: 80vh;
    position: fixed;
    bottom: 90px;
    right: 25px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: $default-box-shadow;
    opacity: 0;
    transform: translateY(900px);
    transition: all ease-in-out 0.3s;

    @include respond(bp600) {
      z-index: 1002 !important;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      max-height: 100vh;
      border-radius: 0;
      transform: translateY(100vh);

      // & .cs-message-list {
      //   height: calc(100vh - 63px - 52px);
      //   max-height: 100%;
      // }
    }

    &--visible {
      opacity: 1;
      transform: translateY(0);
    }

    &--form {
      height: auto;
      max-height: auto;

      @include respond(bp600) {
        height: auto;
        max-height: auto;
        bottom: 90px;
        right: 15px;
        top: auto;
        left: auto;
        width: 320px;
      }
    }
  }

  &__button {
    position: relative;
    box-sizing: border-box;
    width: 64px;
    height: 64px;
    position: fixed;
    bottom: 15px;
    right: 25px;
    border-radius: 15px;
    background-color: $color-data-1;
    box-shadow: $default-box-shadow;
    transition: all ease-in-out 0.3s;
    overflow: hidden;
    cursor: pointer;
    @include center-xy-axis-horizontal;

    @include respond(bp600) {
      right: 15px;
    }

    &--visible {
      .widget-zoe__button-open-icon {
        transform: translateY(-64px);
      }

      .widget-zoe__button-close-icon {
        transform: translateY(0);
      }
    }

    & svg {
      color: #fff;
    }
  }

  &__button-open-icon {
    position: absolute;
    box-sizing: border-box;
    transition: all ease-in-out 0.3s;
    @include center-xy-axis-horizontal;
  }

  &__button-close-icon {
    position: absolute;
    box-sizing: border-box;
    transition: all ease-in-out 0.3s;

    @include center-xy-axis-horizontal;
    transform: translateY(64px);

    & svg {
      font-size: 2.25rem;
    }
  }

  &__attached-files {
    box-sizing: border-box;
    position: fixed;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    bottom: 50px;
    left: 0;
    padding: 15px 10px 10px;
    background-image: linear-gradient(180deg, rgba(#fff, 0.35), rgba(#fff, 1));
    // border-top: 1px solid $color-data-2;
    margin-top: 15px;
    width: 100%;
    font-size: 0.8rem;
    z-index: 1001;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 10px; // Add spacing between thumbnails

      li {
        position: relative;
        width: 80px;
        height: 80px;
        box-shadow: $default-box-shadow;
        border: 1px solid $color-data-2;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .widget-zoe__thumbnail {
          width: 100%;
          height: 100%;
          object-fit: cover; // Maintain aspect ratio
        }

        .widget-zoe__remove-button {
          position: absolute;
          top: 3px;
          right: 3px;
          background: $color-secondary;
          color: white;
          border: none;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.3s;

          &:hover {
            background: $color-secondary-light;
          }
        }
      }
    }
  }

  &__form {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    background-color: #fff;
    padding: 1em;
  }

  &__form-title {
    font-size: 1rem;
    font-weight: 300;
    color: $color-data-1;
    margin: 0;
  }

  &__form-subtitle {
    font-size: 0.9rem 0 0.25rem;
    color: $color-data-1;
    margin: 0;
  }

  &__form-warning {
    font-size: 0.8rem;
    color: #666;
    margin: 0;
    text-align: center;
  }
}

.cs-message__content--clickable {
  text-decoration: underline;
  cursor: pointer !important;
}

.cs-message__content--file-out {
  color: #fff !important;
  & .cs-message__html-content,
  & .cs-message__content {
    color: #fff !important;
    font-weight: 700 !important;
    // background-color: $color-data-2 !important;
    text-decoration: underline !important;
  }
}

.cs-message__content--file-in {
  color: #fff !important;
  & .cs-message__html-content,
  & .cs-message__content {
    color: $color-data-1 !important;
    font-weight: 700 !important;
    // background-color: $color-data-2 !important;
    text-decoration: underline !important;
  }
}

p.widget-zoe__chat-content-message-date {
  margin: 0.5rem 0 0 !important;
  font-size: 0.7rem;
  text-align: right;

  &--outgoing {
    text-align: right;
  }

  &--incoming {
    text-align: left;
  }
}

div.cs-message__content-img-container {
  position: relative;
  bottom: 1.2rem;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  object-fit: cover;
  overflow: hidden;
  @include center-xy-axis-horizontal;
}

img.cs-message__content-img {
  width: 100%;
  height: auto;
}

@media print {
  .widget-zoe {
    display: none !important;
  }
}
