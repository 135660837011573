@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.file-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  box-sizing: border-box;

  &--no-margin-bottom {
    margin-bottom: 0;
  }

  & * {
    box-sizing: border-box;
  }

  &__label {
    position: absolute;
    background-color: #fff;
    padding: 0.1rem 0.4rem;
    font-family: $font-primary;
    font-weight: 700;
    color: $color-grey-dark-5;
    border-radius: $default-border-radius;
    font-size: 0.9rem;
    left: 1.8rem;
    top: -0.8rem;
  }
}

.image-upload.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.file-upload__img-preview {
  margin-top: 1rem;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  margin-bottom: 1rem;
  object-fit: cover;
}

.file-up-img {
  height: 365px;
  width: auto;
  object-fit: fill;
}

.file-up-img--wide {
  width: 365px;
  height: auto;
  object-fit: fill;
}

.file-upload__input {
  display: none;
}

.file-upload__preview-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
}

.file-upload__preview {
  box-sizing: border-box;
  font-size: 1rem;
  padding: 1.05rem 2rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: rgba(0, 0, 0, 0.07);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
  font-family: "Noto Sans", "Helvetica", "Arial", sans-serif;

  &--clickable {
    cursor: pointer;

    & * {
      cursor: pointer;
    }
  }
}

.file-upload__preview-uploaded {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  color: $color-teal;
  cursor: help;

  @include ellipsis;

  & > span {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.5rem;
    align-items: center;
    @include ellipsis;
  }

  &--approved {
    color: $color-teal;
  }

  &--rejected {
    color: $color-secondary;
  }

  &--pending {
    color: $color-orange;
  }
}

.file-upload__preview-link,
.file-upload__preview-link:link,
.file-upload__preview-link:visited {
  display: block;
  font-size: 1.5rem;
  padding: 1.05rem 2rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #333;
  transition: all 0.2s;
  width: 100%;
  font-family: "Noto Sans", "Helvetica", "Arial", sans-serif;
}

.file-upload__preview-link:hover {
  color: #189ab4;
}

.file-upload__btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  height: auto;
  cursor: pointer;
  border: none;
  background-color: #189ab4;
  transition: all 0.2s;

  &:hover,
  &:active {
    background-color: #17859c;
  }

  &--dark-blue {
    background-color: rgba($color-data-1, 0.9);

    &:hover,
    &:active {
      background-color: $color-data-1;
    }

    &:disabled {
      cursor: default;
      background-color: $color-grey-light-4;
    }
  }

  &--cyan {
    background-color: rgba($color-data-2, 0.9);

    &:hover,
    &:active {
      background-color: $color-data-2;
    }
  }

  &--teal,
  &--teal:disabled {
    background-color: $color-teal;
    cursor: default;

    &:hover,
    &:active {
      background-color: $color-teal;
    }
  }
}

.file-upload__btn svg {
  color: #fff;
}

@media screen and (max-width: 599px) {
  .file-upload__preview {
    width: 100%;
  }

  .file-img {
    height: 280px;
    width: auto;
    object-fit: fill;
  }

  .file-up-img--wide {
    width: 280px;
    height: auto;
    object-fit: fill;
  }
}
