@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.sale-item {
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  padding: 15px 20px;
  width: 100%;
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;
  cursor: pointer;
  color: $color-primary-dark;
  display: grid;
  grid-template-columns: max-content minmax(100px, 1fr) max-content;
  grid-column-gap: 15px;
  align-items: center;
  justify-content: space-between;

  &--is-dragging {
    background-color: #e5f8fb;
    // color: #fff;

    // & * {
    //   color: #fff;
    // }
  }

  &__data-item {
    display: block;
    margin: 0;
    max-width: 100%;
    font-size: 0.75rem;
    font-weight: 700;
    @include break-overflown-word;

    &--thin {
      font-size: 0.85rem;
      font-weight: 400;
      margin-bottom: 0.3rem;
    }

    &--thin-less-margin {
      font-weight: 400;
    }

    &--invalid {
      color: $color-error;
    }
  }

  &__data-item-sec {
    display: block;
    margin: 0;
    max-width: 100%;
    font-size: 0.85rem;
    font-weight: 700;
    @include break-overflown-word;

    &--thin {
      font-weight: 400;
      margin-bottom: 0.3rem;
    }
  }

  &__user-data {
    display: grid;
    grid-template-columns: max-content minmax(100px, 1fr);
    align-items: center;
    grid-column-gap: 0.4rem;
    margin-top: 0.1rem;
    max-width: 100%;

    & svg {
      font-size: 0.9rem;
      color: $color-primary-dark;
      transition: all 0.2s ease-in-out !important;
    }
  }

  &__user-data-content {
    max-width: 100%;
    @include ellipsis;

    & p {
      display: block;
      font-size: 0.65rem;
      max-width: 100%;
      @include ellipsis;
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 7px;
    align-items: center;
  }

  &__control-btn {
    transition: transform 0.2s ease-in-out;

    &--open {
      transform: rotate(-180deg);
    }
  }

  &__details {
    grid-column: 1 / -1;
  }

  &__details-content {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #bbb;
  }

  &__indicators {
    position: absolute;
    display: flex;
    top: -5px;
    right: 5px;
    color: #fff;

    & svg {
      font-size: 0.97rem;
    }
  }

  &__indicator {
    border-radius: 50%;
    // padding: 0.15rem;
    width: 21px;
    height: 21px;
    @include center-xy-axis-horizontal;

    cursor: help;

    &--loading {
      visibility: hidden;
    }
  }

  &__legend {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 0.75rem;
    grid-gap: 0.5rem;
    color: #fff;
  }

  &__legend-item {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.85rem;
    align-items: center;
  }

  &__legend-txt {
    color: $color-primary-dark;
    margin: 0;
  }

  &__indicator-fin {
    margin-left: 0.3rem;
    background-color: $color-data-3;
    color: #000;
  }

  &__indicator-props {
    margin-left: 0.3rem;
    background-color: $color-data-2;
  }

  &__indicator-views {
    margin-left: 0.3rem;
    background-color: $color-data-1;
  }

  &__indicator-expired {
    margin-left: 0.3rem;
    background-color: #d93911;
  }

  &__indicator-inactive {
    margin-left: 0.3rem;
    background-color: #a90000;
  }

  &__indicator-cold {
    margin-left: 0.3rem;
    background-color: #6583a0;
  }

  &__indicator-warm {
    margin-left: 0.3rem;
    background-color: #f57b00;
  }

  &__indicator-hot {
    margin-left: 0.3rem;
    background-color: #ff0037;
  }

  &__indicator-legend {
    width: 25px;
    height: 25px;
    margin-left: 0;

    & svg {
      font-size: 1.2rem;
    }
  }
}
