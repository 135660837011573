@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.input-rating {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  font-family: $font-primary;
}
