html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fjalla One", sans-serif;
  font-weight: 400;
}

p,
ul,
ol {
  font-family: "Noto Sans", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  background-color: #eee;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

@media screen and (max-width: 599px) {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #999;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
