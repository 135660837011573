@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.dashboard-item {
  &--financings {
    grid-column: 1 / -1;
    width: 100%;
    order: 1;
    min-height: 300px;
    padding: 1rem 0;

    @include respond(bp1800) {
      grid-column: 1 / -1;
    }

    @include respond(bp1200) {
      grid-column: 1 / -1;
    }
    @include respond(bp600) {
      grid-column: 1 / -1;
    }
  }
}