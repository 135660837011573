@import "../abstracts/animations";
@import "../abstracts/mixins";
@import "../abstracts/variables";

.table {
  box-sizing: border-box;
  position: relative;
  display: grid;
  width: 90%;
  max-width: 1200px;
  grid-template-columns: 100%;
  background-color: $color-grey-light-2;
  padding: 10px 0;
  border-radius: $default-border-radius;
  box-shadow: 0px 0px 5px -1px rgb(0 0 0 / 25%);
  justify-content: center;
  margin: 0 0 50px;

  // @include respond(bp1600) {
  //   width: 800px;
  //   grid-template-columns: 800px;
  // }

  // @include respond(bp1200) {
  //   width: 550px;
  //   grid-template-columns: 550px;
  // }

  // @include respond(bp900) {
  //   width: 550px;
  //   grid-template-columns: 550px;
  // }

  @include respond(bp600) {
    width: 93%;
  }

  & > * {
    box-sizing: border-box;
  }

  & p,
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin: 0;
  }

  &--empty {
    @include center-xy-axis-horizontal;
  }

  &__item-container {
    &:not(:last-child) {
      border-bottom: 1px solid $color-grey-light-3;
    }

    &--is-dragging {
      &:not(:last-child) {
        border-bottom: none;
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 2rem 0;
  }

  &__item {
    position: relative;
    display: grid;
    grid-template-columns: minmax(300px, 1fr) max-content;
    align-items: center;
    padding: 20px;
    margin: 0 10px;
    background-color: $color-grey-light-2;
    border-radius: $default-border-radius;
    transition: background-color 0.2s;
    grid-gap: 20px;

    &--is-dragging {
      background-color: $color-grey-light-3;
    }

    // @include respond(bp1200) {
    //   grid-template-columns: max-content;
    // }

    @include respond(bp900) {
      grid-template-columns: minmax(300px, 1fr) max-content;
    }

    @include respond(bp600) {
      margin: 0;
      grid-template-columns: minmax(150px, 1fr) max-content;
    }
  }

  &__item-title {
    font-size: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;

    @include respond(bp1200) {
      font-size: 15px;
      max-width: 400px;
    }

    @include respond(bp600) {
      max-width: 200px;
    }
  }

  &__item-content {
    margin-top: 5px;
    font-weight: 300;
    font-size: 17px;
    max-width: 800px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--show-all {
      white-space: normal;
      overflow: visible;
    }

    @include respond(bp1600) {
      max-width: 450px;
    }

    @include respond(bp1200) {
      font-size: 15px;
      max-width: 300px;
    }

    @include respond(bp600) {
      max-width: 200px;
    }
  }

  &__item-stats {
    margin-top: 5px;
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    grid-gap: 0 10px;

    &--less-spaced {
      grid-gap: 0 7.5px;
    }
  }

  &__item-stat {
    font-weight: 300;
    font-size: 17px;
    max-width: 450px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $font-primary;

    @include respond(bp1200) {
      font-size: 15px;
      max-width: 350px;
    }

    @include respond(bp900) {
      max-width: 300px;
    }

    @include respond(bp600) {
      max-width: 200px;
    }

    &--bold {
      font-weight: 700;
    }

    &--capitalized {
      text-transform: capitalize;
    }

    &--link {
      cursor: pointer;
      color: $color-primary-light;
    }

    &--date {
      font-size: 14px;
      margin-top: 10px;

      @include respond(bp1200) {
        position: absolute;
        bottom: 23px;
        right: 16px;
      }

      @include respond(bp900) {
        position: relative;
        bottom: 0;
        right: 0;
      }

      @include respond(bp600) {
        position: absolute;
        bottom: 23px;
        right: 16px;
      }
    }
  }

  &__item-actions {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 10px;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;

    @include respond(bp600) {
      grid-gap: 5px;
    }

    &--five-actions {
      grid-template-columns: repeat(5, max-content);
    }
  }

  &__item-action {
    & svg {
      transition: transform 0.3s;
    }

    &--open {
      & svg {
        transition: transform 0.3s;
        transform: rotate(-180deg);
      }
    }
  }

  &__secondary {
    background-color: $color-grey-light-3;
    min-height: 20px;

    &--is-dragging {
      background-color: transparent;
    }
  }

  &__secondary-item {
    position: relative;
    display: grid;
    grid-template-columns: minmax(300px, 1fr) max-content;
    align-items: center;
    padding: 25px 20px;
    margin: 0 10px;
    border-radius: $default-border-radius;
    background-color: $color-grey-light-3;
    transition: background-color 0.2s;
    border-bottom: 1px solid rgba($color-grey-light-4, 0.5);
    grid-gap: 20px;

    &--no-border {
      border-bottom: none;
    }

    &--is-dragging {
      background-color: $color-grey-light-4;
      border-bottom: none;
    }

    &--info-only {
      grid-template-columns: max-content;
      grid-row-gap: 4px;
    }

    @include respond(bp600) {
      grid-template-columns: minmax(153px, 1fr) max-content;
    }
  }

  &__secondary-item-title {
    display: inline-block;
    font-size: 16px;
    font-family: $font-primary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;

    &--highlight {
      color: $color-primary-dark;
    }

    // @include respond(bp600) {
    //   white-space: nowrap;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   max-width: 130px;
    // }
  }

  &__secondary-item-stats {
    margin-top: 5px;
    font-weight: 300;
    font-size: 14px;

    @include respond(bp1200) {
      font-size: 12px;
    }
  }

  &__secondary-item-stat {
    &--bold {
      font-weight: 700;
    }

    &--capitalized {
      text-transform: capitalize;
    }

    &--qtd {
      font-weight: 500;
      color: $color-primary-light;
    }
  }

  &__secondary-item-actions {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: 0 10px;

    @include respond(bp600) {
      grid-gap: 0 5px;
    }

    &--three-actions {
      grid-template-columns: repeat(3, max-content);
    }

    &--five-actions {
      grid-template-columns: repeat(5, max-content);
    }
  }

  &__secondary-add-btn-container {
    @include center-xy-axis-horizontal;
    width: 100%;
    padding: 20px 0;
    background-color: $color-grey-light-3;
  }

  &__item-form-container {
    margin: 30px auto;
    width: 80%;
    display: flex;
    justify-content: center;
  }

  &__mult-items-ext-container {
    margin-top: 4.5px;
  }

  &__mult-items-title {
    font-weight: 700;
  }

  &__mult-items-int-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include respond(bp500) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__mult-items-list {
    margin-top: 5px;
    margin-left: -4.5px;
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;

    @include respond(bp1300) {
      max-width: 400px;
    }

    @include respond(bp1100) {
      max-width: 280px;
    }

    @include respond(bp900) {
      max-width: 400px;
    }

    @include respond(bp700) {
      max-width: 280px;
    }
  }

  &__mult-items-item {
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    padding: 6.5px 14px;
    margin: 4.5px;
    background-color: rgba($color-white, 0.15);
    border-radius: $default-border-radius;
    font-size: 12px;
    line-height: 1;
    transition: all 0.2s;

    &:hover {
      background-color: rgba($color-white, 0.22);
    }
  }
}

.table-metrics {
  position: relative;
  display: grid;
  width: calc(95vw - 250px);
  grid-template-columns: 100%;
  background-color: $color-grey-light-2;
  // border-radius: $default-border-radius;
  // box-shadow: $default-box-shadow;
  margin: 0;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
  box-sizing: border-box;

  @include respond(bp959) {
    max-height: calc(100vh - 64px);
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    max-height: calc(100vh - 80px);
    box-sizing: border-box;
    position: relative;

    @include respond(bp959) {
      max-height: calc(100vh - 64px);
      padding: 15px 0 30px;
    }
  }

  & > * {
    box-sizing: border-box;
  }

  &--3 {
    width: 800px;
  }

  &__header {
    z-index: 1;
    padding: 10px 25px;
    display: grid;
    width: calc(95vw - 250px);
    grid-template-columns: 3fr 2fr repeat(5, 1fr);
    grid-gap: 10px;
    justify-items: start;
    font-family: $font-title;
    font-size: 18px;
    background-color: $color-data-2;
    color: $color-grey-light-2;
    border-top-left-radius: $default-border-radius;
    border-top-right-radius: $default-border-radius;
    position: fixed;
    align-items: center;
    z-index: 10;

    &--3 {
      width: 800px;
      grid-template-columns: 2fr 1fr 1fr;
    }

    &--5 {
      grid-template-columns: repeat(5, 1fr) 26px;
    }

    &--5-btn {
      grid-template-columns: max-content repeat(5, 1fr) 26px;
    }

    &--teal {
      background-color: $color-data-2;
    }

    &--cyan {
      background-color: $color-data-2;
    }

    @include respond(bp900) {
      position: absolute;
    }
  }

  &__filter {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;

    &--wide {
      width: calc(95vw - 250px);
    }
    // align-items: center;
    // align-content: center;
  }

  &__filter-results {
    font-family: "Noto Sans", "Helvetica", "Arial", sans-serif;
    font-size: 0.9rem;
    // background-color: #fff;
    padding: 0.2rem 0;
    border-radius: $default-border-radius;
    // box-shadow: $default-box-shadow;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.2rem;
    max-width: 100%;
    align-content: center;

    @include respond(bp800) {
      font-size: 0.75rem;
    }
  }

  &__filter-results-item {
    max-width: 100%;
    color: $color-primary-dark;
    @include ellipsis;

    &--blue {
      color: $color-primary;
    }

    &--red {
      color: $color-secondary;
    }
  }

  &__content {
    margin-top: 48.7px;
    padding-bottom: 15px;
    min-height: 80px;
  }

  &__row-container {
    position: relative;
  }

  &__row {
    position: relative;
    display: grid;
    grid-template-columns: 3fr 2fr repeat(5, 1fr);
    grid-gap: 15px;
    padding: 12px 21px 12px 25px;
    text-align: left;
    justify-items: start;
    align-items: center;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: all 0.3s;

    &:hover,
    &:active {
      background-color: rgba($color-primary, 0.15);
    }

    &--non-clickeable {
      cursor: default;

      &:hover,
      &:active {
        background-color: #fff;
      }
    }

    &--no-border {
      border-top: 0 solid #ddd !important;
    }

    &:first-child {
      border-top: 1px solid #ddd;
    }

    &--3 {
      grid-template-columns: 2fr 1fr 1fr;
    }

    &--5 {
      grid-template-columns: repeat(5, 1fr) 26px;
    }

    &--5-btn {
      grid-template-columns: max-content repeat(5, 1fr) 26px;
    }

    &--1fr-4fr {
      padding: 0 21px 0 25px;
      grid-template-columns: 1fr 4fr 2px 2px 2px 20px;
      justify-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__row-opt-btn {
    // position: absolute;
    // z-index: 5;
    // right: 0.75rem;
    // top: 50%;
    // transform: translateY(-50%);
    // background-color: #fff;
    // border-radius: 50%;
  }

  &__row-info,
  &__row-info-btn {
    font-size: 0.85rem;
    max-width: 100%;
    font-family: "Noto Sans", "Helvetica", "Arial", sans-serif;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    color: $color-primary-dark;
    @include break-overflown-word;

    &--highlight {
      font-weight: 700;
    }

    &--teal {
    }

    &--blue {
      color: $color-primary;
    }

    &--red {
      color: $color-secondary;
    }

    &--2-data {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.2rem;
    }

    &--multiple {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
    }
  }

  &__row-sub-info {
    display: block;
  }

  &__empty-row {
    padding: 20px 25px;
    @include center-xy-axis-horizontal;
    font-weight: 700;
  }

  &__row-item {
    &--details {
      cursor: help;
    }
  }

  // @include respond(bp1800) {
  //   &,
  //   &__header,
  //   &__filter {
  //     width: 1240px;

  //     &--3 {
  //       width: 800px;
  //     }
  //   }
  // }

  // @include respond(bp1600) {
  //   &,
  //   &__header,
  //   &__filter {
  //     width: 1100px;
  //   }
  // }

  // @include respond(bp1500) {
  //   &,
  //   &__header,
  //   &__filter {
  //     width: 1000px;
  //   }
  // }

  // @include respond(bp1400) {
  //   &,
  //   &__header,
  //   &__filter {
  //     width: calc(96vw - 250px);
  //   }
  // }

  // @include respond(bp1300) {
  //   &,
  //   &__header,
  //   &__filter {
  //     width: 900px;
  //   }
  // }

  @include respond(bp1200) {
    // & {
    //   width: 700px;
    // }

    // &__header,
    // &__filter {
    //   width: 700px;
    // }

    &__header,
    &__row {
      grid-template-columns: 3fr 2fr 1fr;

      &--3 {
        grid-template-columns: 2fr 1fr 1fr;
      }

      &--5 {
        grid-template-columns: repeat(3, 1fr) 26px;
      }

      &--5-btn {
        grid-template-columns: max-content repeat(3, 1fr) 26px;
      }

      &--1fr-4fr {
        grid-template-columns: 1fr 2fr 2px 24px;
      }
    }

    &__row-info,
    &__header-item {
      &:nth-child(5),
      &:nth-child(4) {
        display: none;
      }
    }

    &__row-info-btn,
    &__header-item-btn {
      &:nth-child(6),
      &:nth-child(5) {
        display: none;
      }
    }
  }

  // @include respond(bp1100) {
  //   &,
  //   &__header,
  //   &__filter {
  //     width: 600px;
  //   }
  // }

  @include respond(bp959) {
    &,
    &__header {
      width: 95vw;
    }

    &__filter {
      &--wide {
        width: 95vw;
      }
    }
  }

  @include respond(bp700) {
    &,
    &__header {
      width: 96vw;
    }

    &__filter {
      &--wide {
        width: 96vw;
      }
    }
  }

  @include respond(bp600) {
    // &,
    // &__header,
    // &__filter {
    //   width: 340px;
    // }

    &__header,
    &__row {
      grid-template-columns: 3fr 2fr 26px;

      &--5-btn {
        grid-template-columns: max-content 3fr 2fr 26px;
      }

      &--1fr-4fr {
        grid-template-columns: 3fr calc(2fr + 26px);
      }
    }

    &__row-info,
    &__header-item {
      &:nth-child(3) {
        display: none;
      }
    }

    &__row-info-btn,
    &__header-item-btn {
      &:nth-child(4) {
        display: none;
      }
    }
  }
}
