@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.ticket-item {
  position: relative;
  display: grid;
  align-items: center;
  padding: 20px 0 20px;
  background-color: $color-grey-light-2;
  border-radius: $default-border-radius;
  border-bottom: solid 1px $color-grey-light-5;
  transition: background-color 0.2s;
  grid-gap: 0 20px;

  @include respond(bp600) {
    padding: 10px 0 10px;
  }

  &__main-info {
    font-size: 0.95rem;
    display: grid;
    grid-template-columns: max-content 3fr 2fr;
    grid-gap: 1.5rem;
    padding: 0 40px;

    @include respond(bp1500) {
      grid-template-columns: max-content 2fr 1fr;
    }

    @include respond(bp1300) {
      padding: 0 20px;
      font-size: 0.85rem;
    }

    @include respond(bp1200) {
      grid-template-columns: 1fr max-content;
    }

    @include respond(bp1100) {
      grid-gap: 2.5rem;
    }

    @include respond(bp600) {
      padding: 0 10px;
      grid-template-columns: 1fr;
    }
  }

  &__main-info-visual-data {
    display: flex;
    flex-direction: column;
  }

  &__main-avatar {
    box-sizing: border-box;
    width: 125px;
    height: 125px;
    overflow: hidden;
    @include center-xy-axis-horizontal;
    object-fit: cover;
    align-self: center;
    border-radius: 50%;

    @include respond(bp1400) {
      width: 100px;
      height: 100px;
    }

    @include respond(bp1200) {
      display: none;
    }
  }

  &__main-avatar-img {
    width: 100%;
    height: auto;
  }

  &__main-info-primary {
    @include break-overflown-word;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: start;
    align-content: center;
    grid-gap: 0.3rem 1rem;
    color: $color-primary-dark;

    & > p {
      margin: 0;

      @include respond(bp600) {
        @include ellipsis;
      }
    }
  }

  &__main-info-label {
    font-weight: 700;
    // align-self: flex-start;

    &--wide {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: 0.5rem;
      align-items: center;
    }

    &--highlighted {
      font-weight: 700;
      color: $color-data-2;

      & ~ p {
        color: $color-data-2;
      }
    }
  }

  &__main-info-value {
    &--capitalized {
      text-transform: capitalize;
    }

    &--highlighted {
      color: $color-data-2;
      font-weight: 700;
    }

    &--cyan {
      color: $color-data-2;
      font-weight: 700;
    }

    &--teal {
      color: $color-teal;
      font-weight: 700;
    }

    &--orange {
      color: $color-orange;
      font-weight: 700;
    }

    &--red {
      color: $color-secondary;
      font-weight: 700;
    }
  }

  &__value-aligned {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.25rem;
  }

  &__main-info-secondary {
    font-size: 0.9rem;
    color: $color-primary-dark;
    font-family: "Noto Sans", sans-serif;
    margin: 1rem 0;

    @include respond(bp1300) {
      font-size: 0.8rem;
    }

    &--mult {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.15rem;

      & span {
      }
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    grid-gap: 0.5rem;
    // padding: 1rem;
    // border: 3px solid $color-primary;
    // border-radius: 15px;

    @include respond(bp1300) {
      font-size: 0.85rem;
    }

    @include respond(bp1100) {
      align-self: start;
    }

    @include respond(bp600) {
      display: none;
    }

    // @include respond(bp600) {
    //   grid-template-columns: max-content;
    //   align-content: start;
    //   grid-gap: 0.3rem;
    //   justify-content: center;
    // }
  }

  &__actions-secondary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }

    & button {
      border-radius: 5px;
    }

    & svg {
      font-size: 1.6rem;
    }

    @include respond(bp1100) {
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
    }

    @include respond(bp600) {
      display: none;
    }
  }

  &__actions-secondary-mobile {
    display: none;

    @include respond(bp600) {
      display: grid;
      width: 100%;
      grid-column: 1 /-1;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
      margin-top: 0.5rem;
      justify-items: stretch;

      &--3 {
        grid-template-columns: 1fr 1fr 1fr;
      }

      & button {
        border-radius: 5px;
        width: 100%;
      }

      & svg {
        font-size: 1.6rem;
      }
    }
  }

  &__actions-main {
    @include respond(bp1100) {
      display: none;
    }
  }

  &__actions-main-mobile {
    display: none;

    @include respond(bp1100) {
      margin-top: 0.5rem;
      grid-column: 1 / -1;
      display: block;
    }

    @include respond(bp600) {
      margin-top: 0;
    }
  }

  &__action {
    & svg {
      transition: all 0.2s ease-in-out;
    }

    &--teal {
      color: $color-teal;

      & svg {
        color: $color-teal;

        &:disabled {
          color: #eee;
        }
      }
    }

    &--cyan {
      color: $color-data-2;

      & svg {
        color: $color-data-2;

        &:disabled {
          color: #eee;
        }
      }
    }

    &--disabled {
      color: #bbb;

      & svg {
        color: #bbb;
      }
    }

    &--open {
      & svg {
        transition: all 0.2s ease-in-out;
        transform: rotate(-180deg);
      }
    }
  }

  &__secondary-info,
  &__mods-info {
    margin-top: 20px;
    font-size: 0.8rem;
    @include break-overflown-word;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.3rem 1rem;
    color: $color-primary-dark;
    background-color: rgba(#ccc, 0.2);
    padding: 15px 20px;

    @include respond(bp900) {
      grid-template-columns: max-content 1fr;
      font-size: 0.75rem;
    }

    @include respond(bp600) {
      margin: 0;
      grid-template-columns: max-content 1fr;
    }

    &--one-row {
      grid-template-columns: 1fr;

      @include respond(bp900) {
        grid-template-columns: 1fr;
      }

      @include respond(bp600) {
        grid-template-columns: 1fr;
      }
    }

    & > p {
      margin: 0;
    }
  }

  &__secondary-info-sub-item {
    font-size: 0.8rem;
    @include break-overflown-word;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.3rem 1rem;
    color: $color-primary-dark;

    border-bottom: 1px solid #888;

    @include respond(bp900) {
      grid-template-columns: max-content 1fr;
      font-size: 0.75rem;
    }

    @include respond(bp600) {
      margin: 0;
      grid-template-columns: max-content 1fr;
    }

    & > p {
      margin: 0;
    }

    &:not(:first-child) {
      padding: 1rem 0 1.2rem;
    }

    &:first-child {
      padding: 0 0 1.2rem;
    }
  }

  &__mods-info {
    grid-template-columns: 1fr;
  }

  &__status {
    // grid-column: 1 / -1;
    font-family: $font-primary;
    font-weight: 700;
    border-radius: 5px;
    text-transform: uppercase;

    &--positive_light {
      color: $color-data-2;
    }

    &--positive_dark {
      color: $color-data-1;
    }

    &--negative {
      color: $color-secondary;
    }

    &--warning {
      color: $color-orange;
    }

    &--neutral {
      color: #888;
    }

    &--sm-width {
      max-width: 100px;
    }

    &--md-width {
      max-width: 150px;
    }

    &--lg-width {
      max-width: 250px;
    }
  }

  &__whatsapp-cta {
    margin-top: 0.25rem;
    grid-column: 1 / -1;
    padding: 0.35rem 0.2rem;
    background-color: $color-data-2;
    color: #fff;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    font-family: $font-primary;
    font-weight: 700;
    transition: all 0.2s;
    @include center-xy-axis-horizontal;

    &:hover,
    &:active {
      background-color: rgba($color-data-2, 0.9);
    }

    &--light {
      background-color: $color-data-3;
      color: $color-data-1;

      &:hover,
      &:active {
        background-color: rgba($color-data-3, 0.9);
      }
    }

    &--teal {
      background-color: $color-teal;

      &:hover,
      &:active {
        background-color: rgba($color-teal, 0.9);
      }
    }

    &--disabled {
      color: #fff;
      background-color: #bbb;

      &:hover,
      &:active {
        color: #fff;
        background-color: #bbb;
      }
    }
  }

  &__main-info-title {
    color: $color-data-2;
    font-weight: 700;
    grid-column: 1 / -1;
    font-size: 0.95rem;
  }

  &__sub-items {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr;

    & p {
      margin: 0;
    }

    &--mg-top {
      margin-top: 0.75rem;
    }
  }

  &__sub-item {
    display: grid;
    position: relative;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
    align-items: center;
    padding: 0.75rem;
    border: 1px solid $color-grey-light-5;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 0.65rem;

    &--one-col {
      grid-template-columns: 1fr;
    }
  }

  &__sub-item-data {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0.3rem 1rem;
    align-items: center;
    @include break-overflown-word;
    & > p {
      @include break-overflown-word;
    }

    &--one-col {
      grid-template-columns: 1fr;
    }
  }

  &__sub-item-actions {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 0.3rem;
  }

  &__form-title {
    padding: 0.5rem 1rem;
    margin: 0;
    color: $color-data-2;
    font-weight: 300;
    font-size: 1.2rem;
  }

  &__form-subtitle {
    padding: 0 1rem 0.25rem;
    margin: 0;
    color: $color-data-1;
    font-weight: 300;
    font-size: 0.9rem;
  }

  &__form {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 400px;

    @include respond(bp500) {
      min-width: 300px;
    }
  }
}
