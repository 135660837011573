@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.dashboard-item {
  &--gen-sales {
    grid-column: 1 / 5;
    width: 100%;
    order: 3;
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-gap: 1.2rem;
    justify-content: center;
    min-height: 300px;

    @include respond(bp1800) {
      grid-column: 1 / 7;
    }

    @include respond(bp1200) {
      grid-template-columns: 150px 1fr;
    }

    @include respond(bp700) {
      grid-column: 1 /-1;
      grid-template-columns: 170px 1fr;
    }
  }

  &--closed-sales {
    grid-column: 5 / 9;
    width: 100%;
    order: 4;
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-gap: 1.2rem;
    justify-content: center;
    min-height: 300px;

    @include respond(bp1800) {
      grid-column: 7 / 13;
    }

    @include respond(bp1200) {
      grid-column: 7 / -1;
      grid-template-columns: 150px 1fr;
    }

    @include respond(bp700) {
      grid-column: 1 /-1;
      grid-template-columns: 170px 1fr;
    }
  }

  &--conv-rates {
    grid-column: 9 / 13;
    width: 100%;
    order: 5;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.2rem;
    justify-content: center;
    min-height: 300px;

    @include respond(bp1800) {
      grid-column: 1 / 7;
    }

    @include respond(bp700) {
      grid-column: 1 /-1;
    }
  }
  &--sales-in-progress {
    grid-column: 1 / 5;
    width: 100%;
    order: 6;
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-gap: 1.2rem;
    justify-content: center;
    min-height: 300px;

    @include respond(bp1800) {
      grid-column: 7 / -1;
    }

    @include respond(bp1200) {
      grid-template-columns: 150px 1fr;
    }

    @include respond(bp700) {
      grid-column: 1 /-1;
      grid-template-columns: 170px 1fr;
    }
  }

  &--sales-activity {
    grid-column: 5 / -1;
    width: 100%;
    order: 7;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.2rem;
    justify-content: center;
    min-height: 300px;

    @include respond(bp1800) {
      grid-column: 1 / -1;
    }
  }
  &--sales-amounts {
    grid-column: 1 / 8;
    width: 100%;
    order: 8;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.2rem;
    justify-content: center;
    min-height: 300px;

    @include respond(bp1800) {
      grid-column: 1 / 8;
    }
    @include respond(bp1600) {
      grid-column: 1 / 7;
    }

    @include respond(bp700) {
      grid-column: 1 /-1;
    }
  }

  &--sold-power {
    grid-column: 8 / -1;
    width: 100%;
    order: 9;
    display: grid;
    grid-template-columns: 250px max-content 1fr;
    grid-gap: 1.2rem;
    justify-content: center;
    min-height: 300px;

    @include respond(bp1800) {
      grid-column: 8 / -1;
    }

    @include respond(bp1600) {
      grid-template-columns: 250px 1fr;
      grid-column: 7 / -1;
    }

    @include respond(bp1200) {
      grid-template-columns: 150px 1fr;
    }

    @include respond(bp700) {
      grid-column: 1 /-1;
      grid-template-columns: 170px 1fr;
    }
  }
}
