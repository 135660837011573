@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.dashboard-item {
  &--next-schedules {
    grid-column: 1 / -1;
    width: 100%;
    order: 10;
    min-height: 300px;
    padding-left:0;
    padding-right:0;
    padding-bottom: 24px;
  }
}