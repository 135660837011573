@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.history-date-divider {
  width: 100%;
  text-align: center;
  margin: 0;
  font-family: $font-title;
  color: $color-primary-dark;
}

.sale-prop-hist-item {
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  padding: 15px 20px;
  width: 100%;
  border-radius: $default-border-radius;
  box-shadow: $default-box-shadow;
  cursor: pointer;
  color: $color-primary-dark;
  transition: all 0.2s ease-in-out;
  display: grid;
  grid-template-columns: minmax(100px, 1fr) max-content;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: space-between;

  & svg {
    transition: all 0.2s ease-in-out !important;
  }

  &:hover,
  &:active {
    // background-color: rgba($color-primary-dark, 0.9);
    // color: #fff;

    // & svg {
    //   color: #fff;
    // }
  }

  &__data-item {
    display: block;
    margin: 0;
    max-width: 100%;
    font-size: 0.85rem;
    font-weight: 700;
    @include break-overflown-word;

    &--thin {
      font-weight: 400;
      margin-bottom: 0.3rem;
    }
  }

  &__user-data {
    display: grid;
    grid-template-columns: max-content minmax(100px, 1fr);
    align-items: center;
    grid-column-gap: 0.4rem;
    margin-top: 1rem;
    max-width: 100%;

    & svg {
      font-size: 1.55rem;
      color: $color-primary-dark;
      transition: all 0.2s ease-in-out !important;
    }
  }

  &__user-data-content {
    max-width: 100%;
    @include break-overflown-word;

    & p {
      display: block;
      font-size: 0.7rem;
      max-width: 100%;
      @include break-overflown-word;
    }
  }

  &__controls {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 7px;
    align-items: center;
  }

  &__details {
    grid-column: 1 / -1;
  }

  &__details-content {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #bbb;
  }
}
