@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.modal-error {
  &__content {
    &,
    & span,
    & p {
      @include break-overflown-word;
    }
  }
}
