@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.financing-popover {
  &__btn-choice {
    width: 400px;

    @include respond(bp500) {
      width: 300px;
    }
  }

  &__btn-choice-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin: 0 1rem;
  }
}
