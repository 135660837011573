@import "../abstracts/animations";
@import "../abstracts/mixins";
@import "../abstracts/variables";

.btn {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem 2rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all 0.2s;
    position: relative;
    font-size: 1.05rem;
    font-weight: 700;
    border: none;
    text-align: center;
    cursor: pointer;

    @include respond(bp600) {
      font-size: 0.95rem;
    }
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.4);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &--sm-animation {
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 0.5rem 1rem rgba($color-black, 0.4);

      &::after {
        transform: scaleX(1.1) scaleY(1.4);
        opacity: 0;
      }
    }
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 0.25rem 0.5rem rgba($color-black, 0.4);
    background-color: $color-primary-dark;
  }

  &--white {
    background-color: $color-white;
    color: $color-grey-dark-2;

    &::after {
      background-color: $color-white;
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 0.25rem 0.5rem rgba($color-black, 0.4);
      background-color: $color-white;
    }
  }

  &--custom-color {
    background-color: inherit;
    color: $color-grey-light-1;

    &::after {
      background-color: inherit;
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 0.25rem 0.5rem rgba($color-black, 0.4);
      background-color: $color-grey-dark-4;
    }
  }

  &--grey {
    background-color: $color-grey-dark-4;
    color: $color-grey-light-1;

    &::after {
      background-color: $color-grey-dark-4;
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 0.25rem 0.5rem rgba($color-black, 0.4);
      background-color: $color-grey-dark-4;
    }
  }

  &--grey-light {
    background-color: $color-grey-light-2;
    color: $color-grey-dark-1;

    &::after {
      background-color: $color-grey-light-2;
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 0.25rem 0.5rem rgba($color-black, 0.4);
      background-color: $color-grey-light-2;
    }
  }

  &--yellow {
    background-color: $color-primary-dark;
    color: $color-grey-dark-1;

    &::after {
      background-color: $color-primary;
    }
  }

  &--wide {
    &,
    &:link,
    &:visited {
      width: 100%;
    }
  }

  &--small {
    &,
    &:link,
    &:visited {
      padding: 0.9rem 2rem;
      font-size: 1rem;
    }
  }

  &--icon {
    &,
    &:link,
    &:visited {
      padding: 0;
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50%;
      @include center-xy-axis-horizontal;
    }

    & svg {
      font-size: 2.2rem;
      color: $color-grey-dark-2;
    }
  }

  &--icon-smallest {
    &,
    &:link,
    &:visited {
      padding: 0;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      @include center-xy-axis-horizontal;
    }

    & svg {
      font-size: 1.6rem;
      color: $color-grey-dark-2;
    }
  }

  &--icon-extra-small {
    &,
    &:link,
    &:visited {
      padding: 0;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      @include center-xy-axis-horizontal;
    }

    & svg {
      font-size: 1.85rem;
      color: $color-grey-dark-2;
    }
  }

  &--icon-medium {
    &,
    &:link,
    &:visited {
      padding: 0;
      width: 5.25rem;
      height: 5.25rem;
      border-radius: 50%;
      @include center-xy-axis-horizontal;
    }

    & svg {
      font-size: 2.8rem;
      color: $color-grey-dark-2;
    }
  }

  &--icon-big {
    &,
    &:link,
    &:visited {
      padding: 0;
      width: 5.75rem;
      height: 5.75rem;
      border-radius: 50%;
      @include center-xy-axis-horizontal;
    }

    & svg {
      font-size: 3.5rem;
      color: $color-grey-dark-2;
    }
  }

  &--icon-huge {
    &,
    &:link,
    &:visited {
      padding: 0;
      width: 7rem;
      height: 7rem;
      border-radius: 50%;
      @include center-xy-axis-horizontal;
    }

    & svg {
      font-size: 3.8rem;
      color: $color-grey-dark-2;
    }
  }

  &--icon-blue {
    & svg {
      color: $color-fourth;
    }
  }

  &--square {
    &,
    &:link,
    &:visited {
      border-radius: $default-border-radius;
    }

    &::after {
      border-radius: $default-border-radius;
    }
  }

  &:disabled {
    background-color: $color-grey-dark-3;
    color: $color-grey-dark-4;
    cursor: default;

    &::after {
      display: none;
    }

    &:hover {
      transform: none;
      box-shadow: none;

      &::after {
        display: none;
      }
    }

    & svg {
      color: $color-grey-dark-4;
    }
  }
}

.btn-icon {
  &,
  &:link,
  &:visited {
    position: relative;
    width: 4.5rem;
    height: 4.5rem;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    color: $color-grey-light-2;
    transition: all 0.2s;
    @include center-xy-axis-horizontal;
  }

  &:hover {
    background-color: rgba($color-black, 0.15);
  }

  &:active {
    background-color: rgba($color-black, 0.3);
    color: $color-grey-light-1;
  }

  &__notification {
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    background-color: $color-secondary;
    color: $color-white;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond(bp900) {
      height: 2.15rem;
      width: 2.15rem;
      font-size: 1.5rem;
    }
  }

  & svg {
    font-size: 2.2rem;
  }

  @include respond(bp900) {
    width: 5rem;
    height: 5rem;

    & svg {
      font-size: 2.75rem;
    }
  }

  &--medium {
    width: 3rem;
    height: 3rem;

    & svg {
      font-size: 2rem;
    }
  }

  &--small {
    width: 2.5rem;
    height: 2.75rem;

    @include respond(bp900) {
      height: 3.5rem;
    }

    & svg {
      font-size: 1.6rem;

      @include respond(bp900) {
        font-size: 1.8rem;
      }
    }
  }

  &--large {
    width: 5.5rem;
    height: 5.5rem;

    & svg {
      font-size: 3rem;
    }
  }

  &--180-rot {
    & svg {
      transform: rotate(180deg);
    }
  }

  &--yellow {
    &:hover,
    &:active {
      background-color: rgba($color-black, 0);
      color: $color-primary;
    }
  }

  &--yellow-active {
    color: $color-primary;
  }

  &:disabled,
  &--disabled {
    color: $color-grey-light-4;
    cursor: default;

    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }
  }

  &--light {
    color: $color-grey-dark-2;

    &:hover {
      background-color: rgba($color-white, 0.2);
      color: $color-grey-dark-1;
    }

    &:active {
      background-color: rgba($color-white, 0.35);
      color: $color-grey-dark-1;
    }
  }
}

.btn-icon-text {
  &,
  &:link,
  &:visited {
    @include center-xy-axis-horizontal;
    padding: 1rem 1.5rem 1rem 1.2rem;
    cursor: pointer;
    border: none;
    color: $color-grey-light-2;
    border-radius: $default-border-radius;
    transition: all 0.2s;
    background-color: transparent;
    text-decoration: none;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &:hover {
    background-color: rgba($color-black, 0.15);
    color: $color-grey-light-1;

    & svg {
      color: $color-grey-light-1;
    }
  }

  &:active {
    background-color: rgba($color-black, 0.3);
    color: $color-grey-light-1;

    & svg {
      color: $color-grey-light-1;
    }
  }

  & svg {
    color: $color-grey-light-2;
    margin-right: 0.5rem;
    font-size: 2.2rem;
  }

  @include respond(bp900) {
    & svg {
      font-size: 2.75rem;
    }
  }

  &--app-bar {
    padding: 0.8rem 1.5rem 1rem 0.8rem;

    @include respond(bp900) {
      width: 4.5rem;
      height: 4.5rem;
      padding: 0;
      border-radius: 50%;
    }

    & svg {
      margin-right: 0;
    }
  }

  &--small {
    font-size: 1.2rem;

    & svg {
      font-size: 2rem;
    }
  }

  &--disabled {
    color: $color-grey-light-4;
    cursor: default;

    & svg {
      color: $color-grey-light-4;
    }

    &:hover {
      background-color: transparent;
      color: $color-grey-light-4;

      & svg {
        color: $color-grey-light-4;
      }
    }

    &:active {
      background-color: transparent;
      color: $color-grey-light-4;

      & svg {
        color: $color-grey-light-4;
      }
    }
  }
}

.btn-select {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 0.7rem 1rem;
    border-radius: $default-border-radius;
    position: relative;
    font-size: 1.2rem;
    font-weight: 700;
    border: 1px solid $color-grey-light-3;
    cursor: pointer;
    background-color: transparent;
    color: $color-grey-light-3;
    @include center-xy-axis-horizontal;
    transition: all 0.2s;
  }

  &:hover {
    background-color: rgba($color-white, 0.07);
    color: $color-grey-light-2;
    border-color: $color-grey-light-2;
  }

  & svg {
    font-size: 2rem;
    margin-left: 1rem;
    transform: rotate(0);
    transition: transform 0.2s;
  }

  &--active {
    & svg {
      transform: rotate(-180deg);
    }
  }

  &--disabled {
    &,
    &:link,
    &:visited {
      border: 1px solid $color-grey-dark-4;
      cursor: default;
      color: $color-grey-dark-4;
    }

    &:hover {
      background-color: transparent;
      color: $color-grey-dark-4;
      border-color: $color-grey-dark-4;
    }
  }
}

.btn-text {
  &,
  &:link,
  &:visited {
    font-size: $default-font-size;
    color: $color-grey-light-2;
    text-decoration: none;
    border: none;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;
    font-size: 1rem;
    position: relative;
    transition: all 0.2s;
    @include center-xy-axis-horizontal;
  }

  @include respond(bp500) {
    font-size: 1.1rem;
  }

  &:hover {
    color: $color-primary;
  }

  &:active {
    color: $color-primary-dark;
  }

  &--large {
    font-size: 1.3rem;
  }

  & svg {
    transition: transform 0.3s;
    position: absolute;
    right: -0.15rem;
  }

  &--icon-left {
    & svg {
      left: -0.15rem;
    }
  }

  &--active {
    color: $color-primary;

    & svg {
      transform: rotate(-180deg);
    }

    &:disabled {
      color: $color-grey-light-4;
    }
  }

  &:disabled {
    color: $color-grey-light-4;
    cursor: default;

    &:hover {
      color: $color-grey-light-4;
    }

    &:active {
      color: $color-grey-light-4;
    }
  }
}

.btn-icon-outlined {
  &,
  &:link,
  &:visited {
    font-weight: 700;
    font-size: 1rem;
    padding: 1.75rem 0.75rem;
    background-color: transparent;
    color: $color-grey-light-5;
    cursor: pointer;
    border-radius: $default-border-radius;
    text-transform: uppercase;
    width: 12rem;
    border: 2px solid $color-grey-light-5;
    transition: all 0.2s;
    @include center-xy-axis-horizontal;
  }

  & svg {
    font-size: 2.5rem;
    margin-right: 0.7rem;
  }

  &--active {
    color: $color-primary;
    border: 2px solid $color-primary;

    & svg {
      color: $color-primary;
      transition: color 0.2s;
    }
  }

  @include respond(bp800) {
    width: 100%;
  }
}

.btn-back {
  position: fixed;
  left: 28rem;
  bottom: 3rem;
  z-index: 50;

  &--full-width {
    left: 3rem;
  }

  @include respond(bp900) {
    left: 2rem;
    bottom: 2rem;
  }
}

.sqr-big-icon-btn {
  &,
  &:link,
  &:visited {
    width: 100%;
    border-radius: $default-border-radius;
    background-color: $color-grey-dark-3;
    border: none;
    cursor: pointer;
    padding: 4rem 0;
    color: $color-grey-light-2;
    @include center-xy-axis-horizontal;
    transition: background-color 0.2s, color 0.2s;
  }

  &--responsive {
    @include respond(bp1200) {
      padding: 3rem 0;
    }

    @include respond(bp600) {
      padding: 4rem 0;
    }
  }

  &:hover,
  &:active {
    background-color: $color-primary;
    color: $color-grey-dark-3;
    box-shadow: $default-box-shadow;

    & > .sqr-big-icon-btn__icon {
      background-color: rgba($color-white, 0.5);
    }
  }

  &__icon {
    width: 6rem;
    height: 6rem;
    @include center-xy-axis-horizontal;
    border-radius: 50%;
    background-color: rgba($color-white, 0.2);
    margin-right: 3rem;
    transition: background-color 0.2s;

    & svg {
      font-size: 2.75rem;
    }

    &--responsive {
      @include respond(bp1200) {
        width: 3.85rem;
        height: 3.85rem;
        margin-right: 1.6rem;

        & svg {
          font-size: 2.25rem;
        }
      }

      @include respond(bp600) {
        width: 6rem;
        height: 6rem;
        margin-right: 3rem;

        & svg {
          font-size: 2.75rem;
        }
      }
    }
  }

  &__title {
    font-family: $font-title;
    font-size: 2.15rem;
    white-space: nowrap;

    &--responsive {
      @include respond(bp1200) {
        font-size: 1.75rem;
      }

      @include respond(bp600) {
        font-size: 2.15rem;
      }
    }
  }

  &:disabled {
    cursor: default;

    &:hover,
    &:active {
      background-color: $color-grey-dark-3;
      color: $color-grey-light-2;
      box-shadow: none;

      & > .sqr-big-icon-btn__icon {
        background-color: rgba($color-white, 0.2);
      }
    }

    & > .sqr-big-icon-btn__icon {
      color: $color-grey-light-4;
    }

    & > .sqr-big-icon-btn__title {
      color: $color-grey-dark-4;
    }
  }
}
